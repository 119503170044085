import { useMutation } from "@tanstack/react-query";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { logError } from "utils/error";
import { baseApiClient } from "../api.client";

/**
 * Custom hook for handling mutations with Axios and react-query
 *
 * @param url - API endpoint to send mutation requests to
 * @param onMutate - Optional function triggered before mutation occurs, for optimistic updates or side effects
 * @param onSuccess - Optional function triggered if mutation succeeds
 * @param onError - Optional function triggered if mutation fails
 * @param onSettled - Optional function triggered when mutation completes, regardless of success or failure
 *
 * follow docs - https://tanstack.com/query/v4/docs/framework/react/reference/useMutation for all possible useMutationOptions
 *
 *  */
export const useCustomMutation = ({
  url,
  onMutate,
  onSuccess,
  onSettled,
  ...rest
}) => {
  const { notify } = useNotifications();
  return useMutation({
    mutationFn: async (payload) => {
      const response = await baseApiClient.post(url, payload);
      return response?.data?.data;
    },
    onMutate,
    onSuccess,
    // @dev - To have custom error handling, you can override this onError from props
    onError: (error) => {
      logError({
        error,
        when: `useCustomMutation - ${url}`,
        occuredAt: "data/modules/api/hooks/useCustomMutation.js",
      });
      notify(SOMETHING_WENT_WRONG, notification_color_keys.error);
    },
    onSettled,
    ...rest,
  });
};
