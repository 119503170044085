import { is_empty } from "features/Common/utils/common.utils";
import { onUserLogout, setUuid } from "utils/AuthUtil";
import * as Sentry from "@sentry/react";
import { checkOffline } from "utils/validations";
import {
  HTTP_STATUS_ERROR_CODES,
  HTTP_STATUS_SUCCESS_CODES,
  INTERNAL_STATUS_CODES,
  JWT_GRANT_TYPES,
} from "data/api.constants";
import { baseApiClient } from "../api.client";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "features/Common/modules/Storage/modules/LocalStorage/utils/LocalStorage.utils";
import { LOCAL_STORAGE_KEYS } from "features/Common/modules/Storage/modules/LocalStorage/constants/LocalStorage.constants";
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from "features/Common/modules/Storage/modules/SessionStorage/utils/SessionStorage.utils";
import { SESSION_STORAGE_KEYS } from "features/Common/modules/Storage/modules/SessionStorage/constants/SessionStorage.constant";
import { GET_ACCESS_TOKEN } from "data/APIs";

const { AUTHORIZATION_CODE, NEW_AUTHORIZATION_CODE, REFRESH_TOKEN } =
  JWT_GRANT_TYPES;

const {
  NOT_MODIFIED_STATUS_CODE,
  AUTH_TOKEN_MISSING,
  INVALID_AUTH_TOKEN,
  UNKNOWN_USER,
  INVALID_CREDENTAILS,
  INVALID_JWT_TOKEN,
  ACCESS_TOKEN_EXPIRED,
  ACCESS_TOKEN_MISSING,
  REFRESH_TOKEN_EXPIRED,
  REFRESH_TOKEN_MISSING,
} = INTERNAL_STATUS_CODES;
const { INTERNAL_SERVER_ERROR, UNAUTHORIZED } = HTTP_STATUS_ERROR_CODES;
const { HTTP_STATUS_OK, HTTP_STATUS_NO_CONTENT } = HTTP_STATUS_SUCCESS_CODES;

/**
 *
 * Purpose:
 * Adds a response interceptor to the shared `baseApiClient` to handle JWT token validation and refresh.
 * Ensures session continuity by refreshing tokens when expired or invalid and logs out users for critical errors.
 *
 * Key Features:
 * 1. **Token Refresh**: Automatically refreshes `access_token` using `refresh_token` on expiration.
 * 2. **Session Lock**: Prevents multiple simultaneous refresh attempts using session storage locks.
 * 3. **Error Handling**: Handles authentication errors and logs out users for invalid credentials or tokens.
 * 4. **Retry Mechanism**: Retries failed API requests based on a configurable retry count.
 *
 * Usage:
 * Enhances `baseApiClient`—all API calls automatically leverage token validation and refresh logic.
 */
export const initTokenValidationInterceptor = () => {
  const getJwt = async (
    grant_type = AUTHORIZATION_CODE,
    errorConfig = null
  ) => {
    const staffEmail = getLocalStorageItem(LOCAL_STORAGE_KEYS.BD_EMAIL);

    try {
      if (
        getSessionStorageItem(SESSION_STORAGE_KEYS.PROCESSING_JWT) &&
        grant_type !== NEW_AUTHORIZATION_CODE
      )
        return;
      if (grant_type === NEW_AUTHORIZATION_CODE)
        grant_type = AUTHORIZATION_CODE;
      setSessionStorageItem(SESSION_STORAGE_KEYS.PROCESSING_JWT, "true");
      let username = getLocalStorageItem(LOCAL_STORAGE_KEYS.TOKEN_UUID);

      const resp = await baseApiClient.post(GET_ACCESS_TOKEN, {
        client_id: username,
        grant_type: grant_type,
        refresh_token:
          grant_type === REFRESH_TOKEN
            ? getLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)
            : "",
        staff_email: staffEmail,
      });

      if (resp?.status !== INTERNAL_SERVER_ERROR) {
        const respData = resp?.data;
        if (respData?.status === HTTP_STATUS_OK) {
          if (!is_empty(respData?.data?.staff_details?.main_user)) {
            setUuid(respData?.data?.staff_details?.main_user);
          }
          if (!is_empty(respData?.data?.staff_details?.org_uuid)) {
            setLocalStorageItem(
              LOCAL_STORAGE_KEYS.ORG_UUID,
              respData?.data?.staff_details?.org_uuid
            );
          }
          const { access_token, refresh_token } = respData.data.tokens;
          setLocalStorageItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, access_token);
          setLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refresh_token);
          // @dev - retry mechanism to handle transient errors by refreshing the application state. If retry count reaches 0, the logic falls through to return true, indicating no further retries.
          if (
            errorConfig?.fetchOptions?.retry &&
            errorConfig.fetchOptions.retry > 0
          ) {
            // If the fetchOptions object has a retry count greater than 0, decrement the retry count and attempt to reload the page.
            errorConfig.fetchOptions.retry -= 1;
            return window.location.reload();
          } else return true;
        } else if (respData?.status === REFRESH_TOKEN_EXPIRED) {
          return getJwt(NEW_AUTHORIZATION_CODE, errorConfig);
        } else {
          Sentry.captureMessage(
            `Unknown JWT status - API response : ${JSON.stringify(resp)}`
          );
          return onUserLogout(true);
        }
      } else {
        throw new Error(`JWT api_response status: ${resp?.status}`);
      }
    } catch (err) {
      Sentry.captureException(err);
      return onUserLogout(true);
    }
  };

  baseApiClient.interceptors.response.use(
    (response) => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.PROCESSING_JWT);
      return response;
    },
    //API error handling
    async (error) => {
      checkOffline();
      if (
        [
          AUTH_TOKEN_MISSING,
          INVALID_AUTH_TOKEN,
          UNKNOWN_USER,
          INVALID_CREDENTAILS,
          UNAUTHORIZED,
        ].includes(error?.response?.data?.status)
      ) {
        return onUserLogout(true);
      } else if (
        [INVALID_JWT_TOKEN, ACCESS_TOKEN_EXPIRED].includes(
          error?.response?.data?.status
        )
      ) {
        return getJwt(REFRESH_TOKEN, error?.config);
      } else if (
        [
          ACCESS_TOKEN_MISSING,
          REFRESH_TOKEN_EXPIRED,
          REFRESH_TOKEN_MISSING,
        ].includes(error?.response?.data?.status)
      ) {
        return getJwt(AUTHORIZATION_CODE, error?.config);
      } else if (
        [HTTP_STATUS_NO_CONTENT, NOT_MODIFIED_STATUS_CODE].includes(
          error?.response?.data?.status
        )
      ) {
        return error?.response;
      } else {
        throw error;
      }
    }
  );

  // @dev - Axios request interceptor to dynamically set the latest JWT token in headers before every API request.
  // This ensures that even if the access token is updated after login, all subsequent API calls include the latest token.
  baseApiClient.interceptors.request.use((config) => {
    const accessToken = getLocalStorageItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

    if (!is_empty(accessToken)) {
      config.headers["jwt-token"] = accessToken;
    }

    return config;
  });
};
