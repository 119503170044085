import axios from "axios";
import { RETRY_COUNT } from "data/api.constants";
import { LOCAL_STORAGE_KEYS } from "features/Common/modules/Storage/modules/LocalStorage/constants/LocalStorage.constants";
import { getLocalStorageItem } from "features/Common/modules/Storage/modules/LocalStorage/utils/LocalStorage.utils";
import { is_empty } from "features/Common/utils/common.utils";
import { getUserTimezone } from "utils/AuthUtil";
import { initTokenValidationInterceptor } from "./hooks/tokenValidationInterceptor";

// @dev - This apiClient is only our internal API calls.
export const baseApiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}/`,
  headers: {
    Accept: "application/json",
    "auth-token": `${process.env.REACT_APP_PUBLIC_AUTH_TOKEN}`,
    "scoot-origin": "web_app",
    "exly-source": "creator_tool",
    ...(!is_empty(getLocalStorageItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)) && {
      "jwt-token": getLocalStorageItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN),
    }),
    "user-timezone": !is_empty(getUserTimezone())
      ? getUserTimezone()
      : "Asia/Kolkata",
  },
  // @dev - fetchOptions allows customization of the fetch behavior
  fetchOptions: {
    retry: RETRY_COUNT, // defined the number of retry attempts for failed requests
  },
});

// @dev - initializes token validation and refresh logic for all API calls by setting up interceptor on baseApiClient.
initTokenValidationInterceptor(); // called here to ensure global authentication handling wherever baseApiClient is used.

// @dev - If you want to use apiClient for external API calls having different baseUrl and headers, create a new client like the one above.
