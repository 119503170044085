import { useQuery } from "@tanstack/react-query";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { logError } from "utils/error";
import { baseApiClient } from "../api.client";

/**
 * Custom hook for handling mutations with Axios and react-query
 *
 * @param queryKey - Key used to identify and cache the query result
 * @param url - API endpoint to send query request to
 * @param params - API query params
 * @param onError - Optional function triggered if query fails
 *
 * follow docs - https://tanstack.com/query/v4/docs/framework/react/reference/useQuery for all possible useQueryOptions
 *
 *  */
export const useCustomQuery = ({ url, params, queryKey, ...rest }) => {
  const { notify } = useNotifications();
  return useQuery({
    queryKey,
    queryFn: async () => {
      const response = await baseApiClient.get(url, { params });
      return response?.data?.data;
    },
    // @dev - To have custom error handling, you can override this onError from props
    onError: (error) => {
      logError({
        error,
        when: `useCustomQuery - ${url}`,
        occuredAt: "data/modules/api/hooks/useCustomQuery.js",
      });
      notify(SOMETHING_WENT_WRONG, notification_color_keys.error);
    },
    ...rest,
  });
};
